import React from "react"
import styled from "@emotion/styled"
import Check from "../../images/utility/check-green.svg"
import Cross from "../../images/utility/cross.svg"

const Container = styled.div`
  width: 100%;
  padding: 8px 16px 8px 8px;
  display: grid;
  grid-template-columns: 40px 1fr min-content;
  gap: 16px;
  align-items: center;
  background: ${props => props.theme.shade[90]};
  border-radius: 56px;
  &:after {
    width: 16px;
  }
`
const Title = styled.h4`
  margin: 0;
`
const Status = styled.div`
  width: 40px;
  height: 40px;
  border-radius: ${props => props.theme.radius.l};
  position: relative;
  background-color: ${props => props.theme.shade[100]};
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${props =>
      props.status ? `url(${Check})` : `url(${Cross})`};
  }
`
const Chevron = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  width: 12px;
  height: 12px;
  background: transparent;
  border-right: 4px solid ${props => props.theme.rose};
  border-top: 4px solid ${props => props.theme.rose};
`

export default function Results({ title, results, correct, status, onClick }) {
  return (
    <Container onClick={onClick}>
      <Status status={status} />
      <Title>{title}</Title>
      <Chevron />
    </Container>
  )
}
