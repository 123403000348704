import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
// Having to import theme separate because theme outside global scope
import theme from "../../utils/theme"
import Button from "../form/Button"

const Inner = styled.div`
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.peach};
  border-radius: ${props => props.theme.radius.l};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 24px;
  text-align: center;
  display: grid;
  grid-template-rows: 1fr min-content;
  gap: 16px;
  min-width: 300px;
  min-height: 320px;
  max-height: 480px;
  animation: ${props =>
    props.fadeOut ? "fadeOut 0.4s ease-in-out" : "fadeIn 0.4s ease-in-out"};
  animation-fill-mode: forwards;
  will-change: opacity, transform;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.4) translate(0, -10px) rotate(4deg);
    }
    to {
      opacity: 1;
      transform: scale(1) translate(0, 0) rotate(0deg);
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: scale(1) translate(0, 0) rotate(0deg);
    }
    to {
      opacity: 0;
      transform: scale(0.4) translate(0, -10px) rotate(4deg);
    }
  }
`
const Content = styled.div`
  overflow: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`
const Title = styled.h2`
  font-family: "Alt Goth", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => (props.textColor ? props.textColor : "white")};
  font-size: 2.6rem;
  margin: 0 0 auto;
`
const Text = styled.div`
  color: ${props => (props.textColor ? props.textColor : "white")};
  margin-bottom: auto;
`

const ModalLinkPara = styled.p`
  margin-top: 1rem;
  margin-bottom: 0;
`

const ModalLink = styled.a`
  color: currentColor;
`

const Grid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 8px;
`

export default function DefaultModal({
  title,
  text,
  textColor,
  backgroundColor,
  buttonText,
  isModalOpen,
  onCloseModal,
  afterCloseModal,
  fadeOut,
  linkText,
  linkDestination,
  shouldCloseOnOverlayClick,
  altButtons = false,
}) {
  return (
    <div>
      <Global
        styles={css`
          .CustomPortal {
            z-index: 1;
          }
          .CustomOverlay {
            z-index: 1;
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 24px;
          }
          .CustomContent {
            z-index: 1;
            margin: auto;
            border-radius: ${theme.radius.xl};
            outline: none;
          }
        `}
        theme={theme}
      />
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        closeTimeoutMS={350}
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={onCloseModal}
        onAfterClose={afterCloseModal}
        className={"CustomContent"}
        portalClassName={"CustomPortal"}
        overlayClassName={"CustomOverlay"}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <Inner backgroundColor={backgroundColor} fadeOut={fadeOut}>
          <Content>
            {title && <Title textColor={textColor}>{title}</Title>}
            {text && <Text textColor={textColor}>{text}</Text>}
          </Content>
          {linkText && linkDestination && !altButtons && (
            <ModalLinkPara>
              <ModalLink href={linkDestination}>{linkText}</ModalLink>
            </ModalLinkPara>
          )}
          {buttonText && !altButtons && (
            <Button
              backgroundColor={backgroundColor}
              buttonStyle={"inverted"}
              onClick={onCloseModal}
            >
              {buttonText}
            </Button>
          )}
          {altButtons && (
            <Grid>
              <Button
                buttonStyle={"ghost"}
                backgroundColor={props => props.theme.shade[100]}
                textColor={backgroundColor}
                onClick={onCloseModal}
              >
                Close
              </Button>
              <a
                href={linkDestination}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  buttonStyle={"inverted"}
                  backgroundColor={backgroundColor}
                >
                  {linkText}
                </Button>
              </a>
            </Grid>
          )}
        </Inner>
      </Modal>
    </div>
  )
}

DefaultModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  buttonText: PropTypes.string,
}
