import styled from "@emotion/styled"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Modal from "../../components/block/Modal"
import Button from "../../components/form/Button"
import ButtonLink from "../../components/form/ButtonLink"
import Result from "../../components/form/Result"
import Layout from "../../components/global/layout"
import { urls } from "../../data/urls"
import theme from "../../utils/theme"

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr max-content;
  gap: 32px;
  height: 100%;
  padding: 24px 24px 32px 24px;
`
const ResultsGrid = styled.div`
  display: grid;
  gap: 8px;
  overflow: auto;
  padding: 16px 0;
  mask-image: linear-gradient(
    to top,
    transparent 0px,
    black 16px,
    black calc(100% - 16px),
    transparent 100%
  );
`
const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`

export default function ResultsPage() {
  const [modalFadeOut, setModalFadeOut] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({
    title: "",
    text: "",
    linkText: "",
    linkDestination: "",
  })

  function closeModal() {
    setModalFadeOut(true)
    setModalOpen(false)
  }

  function afterCloseModal() {
    setModalFadeOut(false)
  }

  const quizResults = useSelector(state => state.app.quiz1)

  useEffect(() => {
    if (!quizResults || !quizResults.length) {
      navigate(urls.KYV.QUIZ, { replace: true })
    }
  }, [quizResults])

  if (quizResults && quizResults.length) {
    return (
      <Layout>
        <Modal
          isModalOpen={isModalOpen}
          onCloseModal={closeModal}
          afterCloseModal={afterCloseModal}
          fadeOut={modalFadeOut}
          title={modalContent.title} //TODO Move to JSON
          text={modalContent.text} //TODO MOVE TO JSON
          linkText={modalContent.linkText} //TODO move to JSON
          linkDestination={modalContent.linkDestination} //TODO move to JSON
          backgroundColor={theme.teal}
          textColor={theme.shade[100]}
          shouldCloseOnOverlayClick={true}
          altButtons={true}
        />
        <Grid>
          <ResultsGrid>
            <Result
              onClick={() => {
                setModalContent({
                  title: "Mons pubis",
                  text:
                    "This is the fatty flesh that covers the pubic bone. This is one of the places where pubic hair grows — beginning to appear during puberty. Pubic hair varies in in colour. Some people choose to remove some or all of their pubic hair, but this should be a personal choice.",
                  linkText: "Learn more",
                  linkDestination:
                    "https://www.brook.org.uk/your-life/vaginas-and-vulvas/",
                })
                setModalOpen(true)
              }}
              title="Mons pubis"
              status={quizResults[0]}
            />
            <Result
              onClick={() => {
                setModalContent({
                  title: "Urethra",
                  text:
                    "Where your wee comes out. The urethra is small and hard to see. Some women worry about the urethra being penetrated during sex instead of the vagina, but it’s far too small for that to happen.",
                  linkText: "Learn more",
                  linkDestination:
                    "https://www.brook.org.uk/your-life/vaginas-and-vulvas/",
                })
                setModalOpen(true)
              }}
              title="Urethra"
              status={quizResults[1]}
            />
            <Result
              onClick={() => {
                setModalContent({
                  title: "Outer labia",
                  text:
                    "The labia majora as they're known, are the fleshy folds of skin that protect the external genital organs. Pubic hair grows here too.",
                  linkText: "Learn more",
                  linkDestination:
                    "https://www.brook.org.uk/your-life/vaginas-and-vulvas/",
                })
                setModalOpen(true)
              }}
              title="Outer labia"
              status={quizResults[2]}
            />
            <Result
              onClick={() => {
                setModalContent({
                  title: "Inner labia",
                  text:
                    "Also called the labia minora, you'll find these inside the outer labia. They have no pubic hair and they come in all shapes, sizes and colours. Sometimes one side is longer than the other. All completely normal. ",
                  linkText: "Learn more",
                  linkDestination:
                    "https://www.brook.org.uk/your-life/vaginas-and-vulvas/",
                })
                setModalOpen(true)
              }}
              title="Inner labia"
              status={quizResults[3]}
            />
            <Result
              onClick={() => {
                setModalContent({
                  title: "Clitoris",
                  text:
                    "The clitoris is the only organ in the human body designed purely for pleasure which is why it's one of our faves. It's packed full of nerves so it can be extremely sensitive to the touch. The visible part is a small pea-shaped bump with a hood of skin covering it, but most of the clitoris is internal and can't be seen. ",
                  linkText: "Learn more ",
                  linkDestination:
                    "https://www.brook.org.uk/your-life/vaginas-and-vulvas/",
                })
                setModalOpen(true)
              }}
              title="Clitoris"
              status={quizResults[4]}
            />
            <Result
              onClick={() => {
                setModalContent({
                  title: "Vagina",
                  text:
                    "The vagina is the passage that connects the vulva and the uterus. It's pretty amazing: it can expand lengthways and widthways so bring babies into the world, it's where blood leaves the body during your period, too. It is also where fingers, tongues, sex toys or a penis can be inserted during sex, so it's pretty fun, really.",
                  linkText: "Learn more ",
                  linkDestination:
                    "https://www.brook.org.uk/your-life/vaginas-and-vulvas/",
                })
                setModalOpen(true)
              }}
              title="Vagina"
              status={quizResults[5]}
            />
            <Result
              onClick={() => {
                setModalContent({
                  title: "Perenium",
                  text:
                    "The space between your vulva and your anus (your bottom). There are nerve endings in this area for all genders, so it can feel good to stimulate during sexual activity.",
                  linkText: "Learn more",
                  linkDestination:
                    "https://www.brook.org.uk/your-life/vaginas-and-vulvas/",
                })
                setModalOpen(true)
              }}
              title="Perenium"
              status={quizResults[6]}
            />
            <Result
              onClick={() => {
                setModalContent({
                  title: "Hymen",
                  text:
                    "You can't actually see the hymen but it's a ring of thin skin which covers part of the opening of the vagina. When you have sex for the first time there can sometimes be bleeding afterwards. The hymen can also stretch from using tampons and playing sport. No one knows why we have a hymen so don't panic if yours doesn't 'wait' for sex to disappear.",
                  linkText: "Learn more",
                  linkDestination:
                    "https://www.brook.org.uk/your-life/vaginas-and-vulvas/",
                })
                setModalOpen(true)
              }}
              title="Hymen"
              status={quizResults[7]}
            />
          </ResultsGrid>
          <ButtonGrid>
            <ButtonLink
              buttonStyle={"ghost"}
              backgroundColor={props => props.theme.rose}
              to={urls.KYV.QUIZ}
            >
              Play Again
            </ButtonLink>
            <Button
              backgroundColor={props => props.theme.rose}
              onClick={() => {
                navigate(urls.KYV.CARDS, { replace: true })
              }}
            >
              Next
            </Button>
          </ButtonGrid>
        </Grid>
      </Layout>
    )
  } else {
    return <Layout />
  }
}
